.App {
  text-align: center;
  background: linear-gradient(
    to bottom,
    red,
    red,
    orange,
    yellow,
    green,
    blue,
    purple,
    purple
  );
  user-select: none;
}

.thick {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 900;
}

.slay {
  font-style: italic;
  /* justify-content: center; */
  font-family: "Comic Sans MS", cursive;

  align-content: center;
  font-size: 70px;

  /* color: transparent; */
  color: rgb(0, 255, 255);
  mix-blend-mode: difference;

  padding: 0;
  transition: all 0.1s ease-in-out;
}

@media (prefers-reduced-motion: no-preference) {
  .slay {
    animation: App-logo-spin infinite 5s ease-in-out;
  }
}

.slay:active {
  scale: 1.7;
}

.slayclass {
  /* display: flex; */
  align-items: center;
}

.slaybutton {
  background-color: blueviolet;
  border-width: 5px;
  border-color: black;
  border-style: dotted;
  border-radius: 50px;

  font-family: "Comic Sans MS", cursive;
  font-weight: 900;

  cursor: pointer;

  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 50px;

  transition: all 0.1s ease-in-out;
}

.slaybutton:active {
  scale: 0.75;
}

.slaycount {
  font-family: "Comic Sans MS", cursive;
}

.yassified {
  font-family: "Comic Sans MS", cursive;
  background-color: #fa8bff;
  background-image: linear-gradient(
    45deg,
    #fa8bff 0%,
    #2bd2ff 34%,
    #2bff88 60%
  );
  padding: 1vh;
  border-radius: 15px;

  color: black;

  transition: all 0.5s ease-out;
  opacity: 0;
}

.yassifiedvisisble {
  opacity: 1;
  animation: yassified-slay infinite 1s ease-in-out;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes yassified-slay {
  from {
    scale: 1;
  }

  50% {
    scale: 1.25;
  }

  to {
    scale: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
    /* color: hsl(0); */
  }
  to {
    transform: rotate(360deg);
    /* color: hsl(365); */
  }
}

.slaytext {
  position: fixed;
  top: 50px;
  left: 10px;
  rotate: -25deg;

  font-family: "Comic Sans MS", cursive;
  font-size: 25px;
  color: white;
}
